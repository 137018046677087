import React, { useState } from 'react';
import { Modal, Button } from '@ver-uds/react';
import UnstyledButton from '../UnstyledButton/UnstyledButton';

export interface PrivacyActModalProps {
  isStyled?: boolean;
}

const PrivacyActModal = ({ isStyled = false }: PrivacyActModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isStyled ? (
        <Button variant="tertiary" onClick={() => setIsOpen(true)}>
          Privacy Act
        </Button>
      ) : (
        <UnstyledButton type="button" variant="tertiary" onClick={(): void => setIsOpen(true)}>
          Privacy Act
        </UnstyledButton>
      )}
      <Modal id="privacy-act-modal" header="Privacy Act" open={isOpen} close={(): void => setIsOpen(false)}>
        <Modal.Content>
          <p>
            The information provided by this system is subject to the Privacy Act of 1974 (5 U.S.C. &sect; 552a) and
            must be protected from unauthorized disclosure or secondary uses. In accordance with the Privacy Act, the
            information collected and provided to authorized agencies pursuant to this system is covered by the System
            of Records Notice{' '}
            <a href="https://www.federalregister.gov/documents/2020/05/27/2020-11390/privacy-act-of-1974-system-of-records">
              DHS/USCIS-004 Systematic Alien Verification for Entitlements (SAVE) Program System of Records
            </a>
            {/* Adam Hayes:  have to insert a comment after this line or else sonarqube will fail due to ambiguous spacing
             */}
            , May 27, 2020, 85 FR 31798.
          </p>
        </Modal.Content>
        <Modal.Footer>
          <Button type="button" variant="primary" onClick={(): void => setIsOpen(false)}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrivacyActModal;
